const projectDatabase = [
    {
        id: "atharo",
        id2: "atharo2",
        btnId: "btn6",
        cardID: "atharoCard",
        idForModal: "atharo-modal",
        name: "Atharo",
        description: "Saas that streamlines the management of music schools. Over 10,000 monthly users",
        img: 'https://www.atharo.app/home.png',
        url: 'https://www.atharo.app',
        tools: 'React, Next.js, Node.js, MongoDB'
    },
    {
        id: "revivified",
        id2: "revivified2",
        btnId: "btn7",
        cardID: "revivifiedCard",
        idForModal: "revivified-modal",
        name: "Revivified.co",
        description: "Wellness website that allows authors to log in, write and submit articles to be published.",
        img: 'https://www.revivified.co/logo.svg',
        url: 'https://www.revivified.co',
        tools: 'React, Next.js, Node.js, MongoDB'
    },
    {
        id: "fello",
        id2: "fello2",
        btnId: "btn3",
        cardID: "felloCard",
        idForModal: "fello-modal",
        name: "Fello",
        description: "A Drag and Drop todo list, that you'll find familiar.",
        img: '4.jpg',
        url: 'https://the-gaffer-5.github.io/fello-deploy/',
        tools: 'Vue.js, Vuex'
    },
    {
        id: "nerdflix",
        id2: "nerdflix2",
        btnId: "btn1",
        cardID: "nerdflixCard",
        idForModal: "nerdflix-modal",
        name: "NerdFlix",
        description: "A film database. Register or login with Username: throwaway123 Password: password  *Please be patient while Heroku wakes up! *",
        img: '8.jpg',
        url: 'https://prescottflixapp.herokuapp.com/client/',
        tools: 'MERN Stack'
    },
    {
        id: "mtn",
        id2: "mtn2",
        btnId: "btn2",
        cardID: "mtnCard",
        idForModal: "mtn-modal",
        name: "Mountainside Music Academy",
        description: "A reactive website for a prominent music school in Boulder, Colorado",
        img: '1.jpg',
        url: 'https://www.mountainsidemusicacademy.com/',
        tools: 'REACT, Next.js'
    },
    // {
    //     id: "meetup",
    //     id2: "meetup2",
    //     btnId: "btn4",
    //     cardID: "meetupCard",
    //     idForModal: "meetup-modal",
    //     name: "Meetup Clone",
    //     description: "A clone of the Meetup website, using the Meetup API and serverless. Sign in to Meetup to use. Username: Kasta_iväg123 Password: lösenord",
    //     img: '5.jpg',
    //     url: 'https://the-gaffer-5.github.io/meetup/',
    //     tools: 'React, AWS, Serverless'
    // },
    {
        id: "clarks",
        id2: "clarks2",
        btnId: "btn5",
        cardID: "clarksCard",
        idForModal: "clarks-modal",
        name: "Clark's Domebreaker",
        description: "A game created at a game jam at Forbergs in Stockholm. The theme of the jam was flat earth. My contribution was sound/music creation/implementation.",
        img: '6.jpg',
        url: 'https://humbabah.itch.io/clarksdomebreaker',
        tools: 'C#, Unity, FMOD'
    },
    // {
    //     id: "dinner",
    //     id2: "dinner2",
    //     btnId: "btn6",
    //     cardID: "dinnerCard",
    //     idForModal: "dinner-modal",
    //     name: "What's for Dinner?",
    //     description: "A recipe website using the Food2Fork API.",
    //     img: '3.png',
    //     url: 'https://the-gaffer-5.github.io/real-cookbook-app/',
    //     tools: 'jQuery, Bootstrap'
    // },
]

export default projectDatabase