import React, { Component } from 'react'

class BioSection extends Component {

    componentDidMount() {
        window.addEventListener('scroll', this.scroller)
    }
    scroller = () => {
        let theScroll = window.scrollY
        let scrollOffset = window.scrollY - 1100
        if( theScroll > 1100) {
            document.getElementById('skill-box').style.transform = "matrix(1, 0, 0, 1, 0, -" + scrollOffset/2.5 + ")"
        }
    }


    render() {
        return(
            <div>
                <div className="bio-section">
                    <div className="bio-title">OM MIG</div>
                    <div className="bio-pitch">Full Stack-utvecklare som bygger appar som är både funktionella och vackra<span className="period">.</span></div>
                    <div className="bio-paragraph">Jag älskar att bygga appar som förbättrar människors liv och hjälper företag att fungera mer effektivt. Genom att arbeta med många olika typer av kunder har jag byggt produkter som varierar i omfattning, men som alltid fokuserar på både estetik och funktionalitet. Jag har arbetat med projekt som sträcker sig från ett SaaS-hanteringssystem för musikskolor till att skapa en portal för innehållsskapande för en livsstilsbloggare. Kolla in mina projekt nedan för att se vad jag har arbetat med.</div>
                    <div className="skill-box" id="skill-box">
                        <div className="skill-column">
                            <div className="column-title">Frontend</div>
                            <div className="list-item">Javascript / Typescript</div>
                            <div className="list-item">React / Redux</div>
                            <div className="list-item">Vue / Vuex</div>
                            <div className="list-item">Angular</div>
                            <div className="list-item">Next.js</div>
                        </div>
                        <div className="skill-column">
                            <div className="column-title">Dev Tools</div>
                            <div className="list-item">Git</div>
                            <div className="list-item">NPM</div>
                            <div className="list-item">Yarn</div>
                            <div className="list-item">Webpack</div>
                            <div className="list-item">Visual Studio Code</div>
                        </div>
                        <div className="skill-column">
                            <div className="column-title">Backend</div>
                            <div className="list-item">Linux</div>
                            <div className="list-item">AWS</div>
                            <div className="list-item">MongoDB</div>
                            <div className="list-item">RESTful APIs</div>
                            <div className="list-item">GraphQL</div>
                        </div>
                        <div className="skill-column">
                            <div className="column-title">Intressen</div>
                            <div className="list-item">Matlagning</div>
                            <div className="list-item">Skogen</div>
                            <div className="list-item">Svenska</div>
                            <div className="list-item">Tid med vänner</div>
                            <div className="list-item">SEO</div>
                        </div>
                    </div>
                </div>
                <div className="bio-section-mobile">
                    <div className="bio-title-mobile">OM MIG</div>
                    <div className="bio-pitch-mobile">Full Stack-utvecklare som bygger appar som är både funktionella och vackra<span className="period">.</span></div>
                    <div className="bio-paragraph-mobile">Jag älskar att bygga appar som förbättrar människors liv och hjälper företag att fungera mer effektivt. Genom att arbeta med många olika typer av kunder har jag byggt produkter som varierar i omfattning, men som alltid fokuserar på både estetik och funktionalitet. Jag har arbetat med projekt som sträcker sig från ett SaaS-hanteringssystem för musikskolor till att skapa en portal för innehållsskapande för en livsstilsbloggare. Kolla in mina projekt nedan för att se vad jag har arbetat med.</div>
                    <div className="skill-box-mobile" id="skill-box-mobile">
                        <div className="skill-column-mobile">
                            <div className="column-title-mobile">Frontend</div>
                            <div className="list-item-mobile">Javascript / Typescript</div>
                            <div className="list-item-mobile">React / Redux</div>
                            <div className="list-item-mobile">Vue / Vuex</div>
                            <div className="list-item-mobile">Angular</div>
                            <div className="list-item-mobile">Next.js</div>
                        </div>
                        <div className="skill-column-mobile">
                            <div className="column-title-mobile">Dev Tools</div>
                            <div className="list-item-mobile">Git</div>
                            <div className="list-item-mobile">NPM</div>
                            <div className="list-item-mobile">Yarn</div>
                            <div className="list-item-mobile">Webpack</div>
                            <div className="list-item-mobile">Visual Studio Code</div>
                        </div>
                        <div className="skill-column-mobile">
                            <div className="column-title-mobile">Backend</div>
                            <div className="list-item-mobile">Linux</div>
                            <div className="list-item-mobile">AWS</div>
                            <div className="list-item-mobile">MongoDB</div>
                            <div className="list-item-mobile">RESTful APIs</div>
                            <div className="list-item-mobile">GraphQL</div>
                        </div>
                        <div className="skill-column-mobile">
                            <div className="column-title-mobile">Intressen</div>
                            <div className="list-item-mobile">Skogen</div>
                            <div className="list-item-mobile">Svenska</div>
                            <div className="list-item-mobile">Tid med vänner</div>
                            <div className="list-item-mobile">SEO</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BioSection